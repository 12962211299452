// import axios from 'axios';
import { hanatourApi } from '../../main.js';
export default class UserDetails {

    async getUserDetails(id) {

        return await hanatourApi.get(`admin/user/profile-details/${id}`)

    }

    async getuserdetailList(obj) {
        var params = new URLSearchParams(obj).toString();
        console.log(params)
        var id = localStorage.getItem('userid')
        return await hanatourApi.get(`admin/user/user-created-chatrooms/${id}?${params}`)
            .then((res) => res)
            .catch((err) => err)
    }
    
    async logout() {
        return await hanatourApi.post(`admin/user/logout`)
    }
    // async getuserdetailList(id) {

    //     return await axios.get(`admin/user/user-created-chatrooms/${id}`)

    // }
}